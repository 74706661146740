import React from "react"
import { graphql } from "gatsby"
import Container from "../../container/container"
import WineryFeatured from "../../winery/winery-featured"

const WineryBlock = (data) => {
  return (
    <Container gutters pt={2} pb={2} width={'medium'}>
      <WineryFeatured {...data} />
    </Container>
  )
}

export default WineryBlock

export const query = graphql`
  fragment Winery on DatoCmsWineryBlock {
    id: originalId
    title
    subHeading
    locationOverride
    content
    portalId
    buttonText
    ctaText
    ctaLink {
      ... on DatoCmsHomepage {
        originalId
      }
      ... on DatoCmsCollectionParent {
        originalId
      }
      ... on DatoCmsCellar {
        originalId
      }
      ... on DatoCmsPairing {
        originalId
      }
      ... on DatoCmsWine {
        originalId
      }
      ... on DatoCmsGrape {
        originalId
      }
      ... on DatoCmsRegionsPage {
        originalId
      }
      ... on DatoCmsWineriesPage {
        originalId
      }
    }
    featureImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "600", fit: "crop", w: "820", q: 60 }
      )
      alt
    }
    __typename
  }
`
