import React, { useState } from "react"
import { Collapse } from "@mui/material"
import style from "./accordion.mod.scss"
import cn from "classnames"
import CircleArrow from "../icons/circle-arrow"

const Accordion = ({ heading, text, isDefaultOpen }) => {
  const [open, setOpen] = useState(isDefaultOpen)

  const handleOnClick = () => {
    setOpen(!open)
  }

  const classes = cn(style.accordion, {
    [style[`accordion--open`]]: open,
  })

  return (
    <div className={classes} onClick={handleOnClick}>
      <div className={style.accordion__header}>
        <div className={style.accordion__content}>
          <span className={style.accordion__label}>{heading}</span>
          <span className={style.accordion__dropdownIcon}>
            <CircleArrow />
          </span>
        </div>
      </div>
      <Collapse in={open}>
        <div className={style.accordion__body}>{text}</div>
      </Collapse>
    </div>
  )
}

export default Accordion
