import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import style from "../components/news/news.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import ModuleArea from "../components/modular-content/module-area"
import { DateTime } from "luxon"

const NewsDetail = ({ data }) => {
  const { title, content, meta, author, readTime, heroImage, seoMetaTags } = data.datoCmsPost
  let dateString =
    meta && meta.createdAt
      ? DateTime.fromISO(meta.createdAt).toFormat("dd.LL.yyyy")
      : ""
  return (
    <Layout seo={seoMetaTags}>
      <Container gutters width="medium" pt={2}>
        <p className={style.date}>{dateString}</p>
        <h3 className={style.title}>{title}</h3>
        <p>
          {author && <span className={style.author}>By {author}</span>}
          {readTime && (
            <span className={style.readtime}>{readTime} min read</span>
          )}
        </p>
        <GatsbyImage
          image={heroImage.gatsbyImageData}
          alt={heroImage.alt ? heroImage.alt : ""}
        />
        <hr className={style.divider} />
      </Container>
      <ModuleArea blocks={content} />
    </Layout>
  )
}

export default NewsDetail

export const query = graphql`
  query PostQuery($id: String!) {
    datoCmsPost(originalId: { eq: $id }) {
      originalId
      title
      author
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      readTime
      category {
        title
        originalId
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "800", q: 60 }
        )
        alt
      }
      meta {
        createdAt
      }
      content {
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsWineryBlock {
          ...Winery
        }
      }
    }
  }
`
