import React from "react"
import { graphql } from "gatsby"
import Container from "../../container/container"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageBlock = ({
  paddingTop = "none",
  paddingBottom = "none",
  image
}) => {
  return (
    <Container gutters width={'medium'} pt={paddingTop} pb={paddingBottom}>
      <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ""} />
    </Container>
  )
}

export default ImageBlock

export const query = graphql`
  fragment Image on DatoCmsImageBlock {
    id: originalId
    paddingBottom
    paddingTop
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: {w: "1360", h: "700", fit: "crop",  q: 60 }
      )
    }
    __typename
  }
`
