import React from "react"
import { graphql } from "gatsby"
import Container from "../../container/container"
import Accordion from "../../accordion/accordion"

const AccordionBlock = ({ items, paddingTop, paddingBottom }) => {
  return (
    <Container gutters pt={paddingTop} pb={paddingBottom} width={"medium"}>
      {items.map(({ id, heading, text }) => (
        <Accordion key={id} heading={heading} text={text} />
      ))}
    </Container>
  )
}

export default AccordionBlock

export const query = graphql`
  fragment Accordion on DatoCmsAccordionBlock {
    id: originalId
    items {
      text
      heading
    }
    paddingBottom
    paddingTop
    __typename
  }
`
