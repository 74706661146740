import React from "react"
import { graphql } from "gatsby"
import Container from "../../container/container"
import style from "./content-block.mod.scss"

const ContentBlock = ({ content, paddingTop, paddingBottom }) => {
  return (
    <Container gutters pt={paddingTop} pb={paddingBottom} width={'medium'}>
      <div dangerouslySetInnerHTML={{ __html: content }} className={style.contentblock} />
    </Container>
  )
}

export default ContentBlock

export const query = graphql`
  fragment Content on DatoCmsContentBlock {
    id: originalId
    content
    paddingBottom
    paddingTop
    __typename
  }
`
