import React from "react"

const CircleArrow = () => {
  return (
    <svg
      data-name="FAQ + small"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g
        data-name="Ellipse 100"
        stroke-width="1.5"
        stroke="#60003a"
        fill="none"
      >
        <circle cx="16" cy="16" r="16" stroke="none" />
        <circle cx="16" cy="16" r="15.25" />
      </g>
      <path
        data-name="Path 16771"
        d="M10.08 14.12 15.51 20l5.43-5.88"
        stroke="#591934"
        stroke-width="1.5"
        fill="none"
      />
    </svg>
  )
}

export default CircleArrow
