import React from "react"
import { graphql } from "gatsby"
import Container from "../../container/container"
import Lead from "../../global/lead"

const LeadBlock = ({ text, heading, paddingTop, paddingBottom }) => {
  return (
    <Container gutters pt={paddingTop} pb={paddingBottom} width={"small"}>
      <Lead text={text} heading={heading} />
    </Container>
  )
}

export default LeadBlock

export const query = graphql`
  fragment Lead on DatoCmsLeadBlock {
    id: originalId
    heading
    paddingTop
    paddingBottom
    text
    __typename
  }
`
